// Color scheme https://coolors.co/143642-0f8b8d-fffff0-ec9a29-a8201a

$yankees_blue:  #143642;
$dark_cyan:     #0F8B8D;
$ivory:         #FFFFF0;
$carrot_orange: #EC9A29;
$cornell_red:   #A8201A;

// Font weights

$text_weight:     400;
$menu_bar_weight: 400;
$strong_weight:   700;
$title_weight:    900;

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,700,700i,900,900i');

body {
    background-color: $ivory;
    color: $yankees_blue;
    line-height: 1.5;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 22px;
    font-weight: $text_weight;
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
}

#page {
    padding-top: 2em;
    padding-bottom: 2em;
    width: 90%;
    max-width: 700px;
    margin: 0 auto;
    position: relative;
    overflow: visible;
}

#menu-bar {
    font-size: 120%;
    line-height: 1.2;
    font-weight: $menu_bar_weight;
    hyphens: none;
    -webkit-hyphens: none;
    -moz-hyphens: none;
}

#site-title {
    font-weight: $title_weight;
    font-size: 250%;
    line-height: 1;
    margin-bottom: 0.1em;
    hyphens: none;
    -webkit-hyphens: none;
    -moz-hyphens: none;
}

.post-date {
    text-align: right;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: $title_weight;
    line-height: 1.2;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

h1 {
    font-size: 180%;
}

h2, h3, h4, h5, h6 {
    font-size: 150%;
}

a, u {
    text-decoration: none;
    color: $cornell_red;
}

#site-title a, .post-title a {
    color: $yankees_blue;
}

ol, ul {
    padding-left: 0em;
    list-style-position: inside;
}

li {
    margin-top: 1em;
    margin-bottom: 1em;
}

p {
    margin-bottom: 1em;
}

blockquote {
    font-style: italic;
}

strong {
    font-weight: $strong_weight;
}

pre, code, kbd, samp, tt {
    font-size: 0.9em;
}

.left-right-padded {
    padding-left:0.25em;
    padding-right:0.25em;
}

.my-photo {
    width: 12em;
    max-width: 33%;
    margin-top: -2em;
    margin-bottom: 0.5em;
    margin-left: 1.5em;
    float: right;
    border-radius: 50%;
}

.alert {
    color: $ivory;
    background-color: $cornell_red;
    font-weight: $strong_weight;
    padding-left: 0.25em;
    padding-right: 0.25em;
}

.highlight {
    padding-left: 1em;
}

.footnotes {
    margin-top: 3em;
    font-size: smaller;
}

@media (max-width: 400px) {
    body {
        font-size: 16px;
    }
    #site-title {
        font-size: 180%;
    }
}
